<template>
  <!-- 我的订单订单详情 -->
  <el-row class="Detailitem">
    <el-col :span="24" class="header">
      <el-col :span="12">
        <el-col :span="8">{{ orderList.createTime }}</el-col>
        <el-col :span="16">订单ID：{{ orderList.id }} </el-col>
      </el-col>
      <el-col :span="12">订单编号: {{ orderList.sn }}</el-col>
    </el-col>
    <table class="main">
      <!-- <thead>
        <th>订单详情</th>
        <th>数量</th>
        <th>金额</th>
        <th>订单状态</th>
        <th>操作</th>
      </thead> -->
      <tbody>
        <template>
          <tr v-for="(item, i) in orderList.orderDetails" :key="i">
            <td v-if="i < 2" class="tableBox" :width="400">
              <div class="imgBox" @click="jumpGoodsDetail(item)">
                <img :src="item.goodsPic.split(',')[0] | defaultImage" alt="" />
              </div>
              <div class="desBox">
                <p class="desTit">
                  <span :title="item.goodsName">
                    {{ item.goodsName }}
                  </span>
                  <span>x{{ item.goodsCount }}</span>
                </p>
                <p class="desMsg">
                  <span v-if="item.specification"
                    >药品规格：{{ item.specification }}</span
                  >
                </p>
                <p class="desMsg">
                  <span v-if="item.partSpecification">件装规格：{{ item.partSpecification }}</span>
                </p>
              </div>
            </td>
            <td
              v-if="i == 3 && !JoinOrderFlag"
              class="tableBox"
              :width="400"
              @click="jumpDetail(orderList.id)"
            >
              <p class="slText">...</p>
            </td>
            <td
              v-if="i == 3 && JoinOrderFlag"
              class="tableBox"
              :width="400"
              @click="jumpJoinDetail(orderList.id)"
            >
              <p class="slText">...</p>
            </td>


            <!-- 只需要展示1次 -->
            <td v-if="i == 0" :rowspan="orderList.orderDetails.length">
              <p class="text-center">{{ orderList.payableAmount }}</p>
            </td>
            <td v-if="i == 0" :rowspan="orderList.orderDetails.length">
              <p class="text-center">
                {{
                  orderList.status === 1
                    ? "待支付"
                    : orderList.status === 2
                    ? "待发货"
                    : orderList.status === 3
                    ? "待收货"
                    : orderList.status === 4
                    ? "已完成"
                    : "已取消"
                }}
              </p>
            </td>
            <!-- {{JoinOrderFlag}} -->
            <td v-if="i == 0 && !JoinOrderFlag" :rowspan="orderList.orderDetails.length">
              <p
                class="text-center textBtn"
                type="text"
                size="small"
                @click="jumpDetail(orderList.id)"
              >
                订单详情
              </p>
              <p
                v-if="orderList.orderType<3" 
                class="text-center textBtn"
                @click="jumpAgainOrder(orderList.id)"
              >
                再来一单
              </p>
              <p class="text-center textBtn"  v-if="orderList.status === 4 && orderList.isEvaluate==0"  @click="jumpEvaluateOrder(orderList.id)">
                 评价，得5积分
              </p>
              <p class="text-center textBtn"  v-if="orderList.status === 4 && orderList.isEvaluate==1"  >
                 该订单已评价
              </p>

              <p class="text-center textBtn"  v-show="orderList.attachId"  @click="DownAttach(orderList.attachId)">
                下载发票
              </p>
              <!-- <p class="text-center textBtn"  v-show="orderList.status === 4 || orderList.status === 5"  @click="delOrder(orderList.id)">
                  删除订单
                </p>  -->
              <p
                v-if="(orderList.status == 2 || orderList.status == 1 ) && orderList.isSendErp==0"
                class="text-center textBtn"
                @click="editNoteFun(orderList)"
              >
                 <!--  {{orderList.isSendErp==0}} -->
                编辑备注  
              </p>

              <p
                class="text-center textBtn"
                v-if="
                  (orderList.status == 2 || orderList.status === 3)
                "
                @click="jumpRefund(orderList.id)"
              >
              <!--  backType && (orderList.status == 2 || orderList.status === 3) -->
                申请售后
              </p>
              <p
                class="text-center textBtn"
                v-if="orderList.status === 3 || orderList.status === 4"
                @click="DownInfoFun(orderList.id)"
              >
                下载订单药检报告
              </p>

              
              <el-popover
                v-if="orderList.status === 3"
                placement="left"
                width="300"
                trigger="click"
                v-model="visible"
              >
                <h2 class="LogTit">
                  {{ logiVO }} <span>{{ logiNo }}</span>
                </h2>
                <div class="block"  v-if="logiCode=='jd'">
                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.opeTime"
                    >
                      <!-- {{activity.mailNo}}  -->
                      <p>
                        {{ activity.opeTitle }} {{ activity.opeName }}
                      </p>
                      <p>{{ activity.opeRemark }}</p>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div class="block"  v-else-if="logiCode=='db'">
                  <el-timeline>
                     <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.time"
                    >
                      <!-- {{activity.mailNo}}  -->
                      <p>
                        {{ activity.city }} {{ activity.site }} 
                      </p>
                      <p>{{ activity.description }}</p>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div class="block"  v-else-if="logiCode=='yjt'">
                  <el-timeline>
                     <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.time"
                    >
                   
                      <p>{{activity.remark}}</p>
                      <p>{{activity.acceptAddress}} {{activity.acceptTotaltime}}</p>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div class="block"  v-else>
                  <el-timeline>
                     <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.time"
                    >
                      <!-- {{activity.mailNo}}  -->
                      <p>

                        {{activity.context}}
                        <!-- {{ activity.sendState }} {{ activity.acceptAddress }} -->
                      </p>
                      <p>  {{ activity.city }} {{ activity.site }} </p>
                    </el-timeline-item>
                  </el-timeline>
                </div>
               
                <span
                  class="text-center textBtn"
                  slot="reference"
                  @click="CheckLogistics(orderList.deliveryId)"
                  >查看物流</span
                >
              </el-popover>


                <!-- 编辑备注弹窗 -->
                <el-dialog
                  title="编辑备注"
                  :visible.sync="NoteDialogVisible"
                  width="30%"
                  :close-on-press-escape='false'
                  :close-on-click-modal='false'
                  center>
                  <el-col :span="24" class="complimentary">
                      <el-col :span="24" class="remarkBox">
                        <div class="leftRemark">
                          备注：
                        </div>
                        <div class="rightRemark">
                          <el-input
                            type="textarea"
                            v-model="noteMsg"
                            placeholder="请输入您要留言的内容"
                            resize="none"
                          ></el-input>
                        </div>
                      </el-col>
                    </el-col>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="NoteDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="SureNote(orderList.id)">确 定</el-button>
                  </span>
                </el-dialog>

            </td>
            <td  v-if="i == 0 && JoinOrderFlag" :rowspan="orderList.orderDetails.length">
              <p
                class="text-center textBtn"
                type="text"
                size="small"
                @click="jumpJoinDetail(orderList.id)"
              >
                订单详情
              </p>
              <p
                v-if="orderList.orderType<3" 
                class="text-center textBtn"
                @click="jumpAgainOrder(orderList.id)"
              >
                再来一单
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- <el-row class="main">
              <img :src="imgSrc" alt="" v-for="(item,index) in 8" :key="index">
          </el-row>
           <el-row>
              <el-col :span="12">
                  <el-col><span>共<span class="total">3</span>件商品</span><span class="totalWraper">合计<span class="total">￥900.00</span></span></el-col>
              </el-col>
              <el-col :span="12" class="text-right">
                    <el-tag type="info" @click="jumpDetail">查看详情</el-tag>
                    <el-tag type="info" @click="jumpCart" class="anotherList">再来一单</el-tag>
              </el-col>
          </el-row> -->
  </el-row>
</template>

<script>
import defaultImage from "@/assets/defaultImage.png";
import { removeOrder, GetLog, AgainOrder, GetOrderReport,editNote } from "api/order.js";
export default {
  name: "Detail",
  //   data() {
  //     return {
  //       imgSrc:
  //         "https://3akx.oss-cn-beijing.aliyuncs.com/upload/image/202009/7857272f-247b-4f00-8186-4950fca94c14_thumbnail.jpg",
  //     }
  //   },
  data() {
    return {
      defaultImage: defaultImage,
      backType: false,
      visible: false,
      activities: [], //物流信息
      logiVO: "", //  物流
      logiNo: "", //  物流单号
      logiCode:"",
      NoteDialogVisible:false,
      noteMsg:''
    };
  },
  props: {
    orderList: {
      type: Object,
    },
    JoinOrderFlag:{
      type:Boolean
    }
  },
  watch: {
    orderList: function(newVal) {
      console.log(newVal)
      //不能用箭头函数
      this.backType = false;
      if(newVal.orderDetails!==undefined){
           newVal.orderDetails.forEach((item) => {
            //  0  不可售后     1  可售后
            if (item.isCan === 1) {
              this.backType = true;
            }
          });
      }
     
    },
  },
  methods: {
    async  DownAttach(PDFhref){
      console.log(PDFhref)
      let urlList=PDFhref.split(',')
      for (const file of urlList) {  
        await  this.downloadFile(file); // 等待每个文件下载完成  
        await new Promise(resolve => setTimeout(resolve, 1000)); // 等待1秒
      }  
        
    },
    async  downloadFile(fileUrl) {  
      console.log(fileUrl)
      const a = document.createElement('a');  
      a.href = fileUrl;  
      a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);  
      a.style.display = 'none';  
      document.body.appendChild(a);  
      a.click();  
      document.body.removeChild(a);  
    } ,
    // 下载订单药检资料
    DownInfoFun(OrderId) {
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      GetOrderReport(OrderId).then((res) => {
        if (res.data.type == "application/json") {
          console.log(res);
             // 关闭loading
             this.$loading().close();
          this.$message({
            message: "暂无药检报告",
            type: "warning",
          });
        } else {
          // 下载pdf
          this.pdfUrl = window.URL.createObjectURL(
            new Blob([res.data], { type: `application/pdf` })
          );
          const fname = "订单号" + OrderId + "药检报告"; // 下载文件的名字
          const link = document.createElement("a");
          link.href = this.pdfUrl;
          link.setAttribute("download", fname);
          document.body.appendChild(link);
          link.click();
            // 关闭loading
            this.$loading().close();
        }
      });
    },
    // 查看物流
    CheckLogistics(deliveryId) {
      console.log("查看物流");
      GetLog(deliveryId).then((data) => {
        console.log("查看物流",data.data.data)
        if (data.data.code == 200) {
          this.logiVO = data.data.data.logiVO;
          this.logiNo = data.data.data.logiNo;
          this.logiCode = data.data.data.logiCode;
          if (data.data.data.logiInformation == "") {
            this.activities = [];
          } else {
            const routesArray = JSON.parse(data.data.data.logiInformation);
            this.activities = routesArray;
            console.log(this.activities)
            this.activities[0].color = "#0bbd87";
          }
        }
        // this.activities=[
        //     {
        //       content: '支持自定义颜色',
        //       timestamp: '2018-04-03 20:46',
        //       color: '#0bbd87'
        //     }, {
        //       content: '支持自定义尺寸',
        //       timestamp: '2018-04-03 20:46',
        //       size: 'large'
        //     }, {
        //       content: '默认样式的节点',
        //       timestamp: '2018-04-03 20:46'
        //     }]
      });
    },
    // 删除订单
    delOrder(orderIds) {
      let ids = {
        ids: orderIds,
      };
      removeOrder(ids).then((data) => {
        if (data.data.code == 200) {
          this.$message({
            message: data.data.msg,
            type: "success",
          });
          //删除订单 刷新页面
          this.$emit("getData");
          // this.$router.push({ name: "MyOrder" });
        }
      });
    },
    // 商品详情
    jumpGoodsDetail(item) {
      // alert(item.goodsType)
      // if(item.goodsType===4){
      //   console.log(item)
      //   // 跳a+b
      //    this.$router.push({
      //     name: "ComboDetail",
      //     query: { productId: item.goodsId, promotionId: item.promotionId },
      //   });
      // } else
      if (item.goodsType === 6) {
        // 跳套餐详情页
        this.$router.push({
          name: "SetMealDetail",
          query: { promotionId: item.goodsId },
        });
      } else {
        this.$router.push({
          name: "ProductDetail",
          query: {
            id: item.goodsId,
            isRecentExpiration: item.isRecentExpiration,
          },
        });
      }
    },
    jumpDetail(orderId) {
      // 订单详情
      sessionStorage.setItem("orderId", orderId);
      sessionStorage.setItem("orderSn", this.orderList.sn);
      this.$router.push({ path: "/OrderDetail", query: { orderId: orderId } });
    },

    jumpJoinDetail(orderId) {
      // 加盟订单详情
      sessionStorage.setItem("orderId", orderId);
      sessionStorage.setItem("orderSn", this.orderList.sn);
      this.$router.push({ path: "/OrderDetail", query: { orderId: orderId,JoinFlag:true } });
    },


    // 评价
    jumpEvaluateOrder(orderId){
      this.$router.push({ path: "/EvaluateOrder", query: { orderId: orderId } });
    },

    jumpAgainOrder(orderId) {
      // 再来一单
      sessionStorage.setItem("orderId", orderId);
      sessionStorage.setItem("orderSn", this.orderList.sn);

      let orderIds = {
        id: orderId,
      };
      AgainOrder(orderIds).then((data) => {
        if (data.data.code == 400) {
          this.$message({
            message: data.data.msg,
            type: "warning",
          });
          //  this.$router.push({ path: "/cart" });
        }

        //跳购物车
        this.$router.push({ name: "Cart" });
        //跳购物车结算页
        // this.$router.push({ name: "GoodsAmount",query:{id:orderId}});
      });
    },

    jumpRefund(orderId) {
      // 申请售后
      sessionStorage.setItem("orderId", orderId);
      sessionStorage.setItem("orderSn", this.orderList.sn);
      this.$router.push({ name: "ApplySales", query: { orderId: orderId } });
    },
    // 编辑备注
    editNoteFun(orderItem){
        this.noteMsg=orderItem.note
        this.NoteDialogVisible=true
    },
    //确定备注 SureNote()  NoteDialogVisible = false
    SureNote(orderId){
      console.log(orderId)
      let datas={
        id:orderId,// 订单id    
        note: this.noteMsg,// 备注
      }
      editNote(datas).then(res=>{
        if(res.data.code==200){
            this.$message({
              message:'备注修改成功',
              type: "success",
            });
            this.$emit("getData");
        }
      })
      this.NoteDialogVisible = false
    }
  },
  created() {
    if(!this.orderList.orderDetails){
      return
    }
    this.orderList.orderDetails.forEach((item) => {
      //  0  不可售后     1  可售后
      if (item.isCan === 1) {
        this.backType = true;
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
// 备注 样式
.Detailitem{
  /deep/.el-dialog__body{
     background: #fff;
     padding: 0;
  }
  /deep/.el-button--primary {
    color: #FFF;
    background-color: #ff3333;
    border-color: #ff3333;
  }
  .complimentary {
      height: 120px;
      // border: 1px solid #f0f0f0;
      .remarkBox {
        padding: 20px;
        // border-right: 1px solid #f0f0f0;
        height: 120px;
        .leftRemark {
          font-size: 12px;
          color: #666;
          font-weight: bold;
          width: 40px;
          float: left;
        }
        .rightRemark {
          font-size: 12px;
          color: #999;
          width: calc(100% - 40px);
          background: #fff;
          height: 80px;
          float: left;
          /deep/.el-textarea {
            width: 100%;
            height: 100%;
            .el-textarea__inner {
              width: 100%;
              height: 100%;
              border: 1px solid #f0f0f0;
              padding: 10px;
            }
          }
        }
      }
    }
}

.el-popover {
  .LogTit {
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    span {
      color: #666;
      font-size: 13px;
      font-weight: normal;
    }
  }
  .el-timeline-item__content {
    p {
      font-size: 13px;
      color: #666;
      line-height: 20px;
      padding: 5px 0;
    }
  }
}
::-webkit-scrollbar  
{  
    // width:3px;  
     width:2px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
.block{
      height: 300px;
    overflow-y: scroll;
}
.textBtn {
  cursor: pointer;
  &:hover {
    color: #ff3333;
  }
}
.Detailitem {
  margin-bottom: 20px;
  background: #fff;
  font-size: 12px;
  color: #999;
  .header {
    padding: 10px;
    background: #eee;
  }
  table.main {
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    .tableBox {
      padding: 10px 10px 10px 0;
      text-align: left;
      .slText {
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          color: #ff3333;
        }
      }
    }
    thead th {
      text-align: center;
      background-color: #fafafa;
    }
    th,
    td {
      border: 1px solid #e9eaec;
      line-height: 30px;
      text-align: center;
    }
    .imgBox {
      float: left;
      width: 72px;
      height: 72px;
      margin: 0 14px;
      border: 1px solid #f6f6f6;
      img {
        display: inline-block;
        width: 70px;
        height: 70px;
        overflow: hidden;
      }
    }
    .desBox {
      float: left;
      padding: 14px 0;
      width: calc(100% - 120px);
      .desTit {
        width: 100%;
        height: 20px;
        cursor: pointer;
        line-height: 20px;
        span:nth-child(1) {
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          font-weight: bold;
          color: #333;
          float: left;
        }
        span:nth-child(2) {
          font-size: 13px;
          color: #666;
          float: right;
        }
      }
      .desMsg {
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: #999;
      }
    }
  }
}
</style>
